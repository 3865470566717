<template>
  <div>
    <v-form>
      <p class="mb-3">
        The thermochemical equation for the salt being studied in this experiment is:
      </p>
      <v-radio-group v-model="inputs.studentAnswer" :disabled="!allowEditing" class="mb-0 pl-6">
        <v-radio
          v-for="option in optionsShuffled"
          :key="'pt-1-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'ChemUBC154RandomizedTask',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswer: null,
      },
      options1: [
        {
          text: '$\\ce{NH_{4}Br_{(s)} -> NH_{4}^{+}_{(aq)} + Br^{-}_{(aq)}}$',
          value: 'option1a',
        },
        {
          text: '$\\ce{NH_{4}Br_{(s)} -> N^{-}_{(aq)} + 4H^{+}_{(aq)} + Br^{-}_{(aq)}}$',
          value: 'option1b',
        },
        {
          text: '$\\ce{NH_{4}Br_{(s)} -> NH_{4}^{+}_{(s)} + Br^{-}_{(s)}}$',
          value: 'option1c',
        },
        {
          text: '$\\ce{NH_{4}Br_{(s)} -> NH_{4}^{2+}_{(aq)} + Br^{2-}_{(aq)}}$',
          value: 'option1d',
        },
      ],
      options2: [
        {
          text: '$\\ce{KNO_{3(s)} -> K^{+}_{(aq)} + NO_{3}^{-}_{(aq)}}$',
          value: 'option2a',
        },
        {
          text: '$\\ce{KNO_{3(s)} -> K^{+}_{(aq)} + N^{-} + 3O^{2-}_{(aq)}}$',
          value: 'option2b',
        },
        {
          text: '$\\ce{KNO_{3(s)} -> K^{+}_{(s)} + NO_{3}^{-}_{(s)}}$',
          value: 'option2c',
        },
        {
          text: '$\\ce{KNO_{3(s)} -> K^{2+}_{(aq)} + NO_{3}^{2-}_{(aq)}}$',
          value: 'option2d',
        },
      ],
    };
  },
  computed: {
    seed() {
      return this.$inertia.getSeed();
    },
    randomizedOptions() {
      let randomOptionList = seededShuffle(['options1', 'options2'], this.seed);
      return randomOptionList[0] === 'options1' ? this.options1 : this.options2;
    },
    optionsShuffled() {
      return seededShuffle(this.randomizedOptions, this.seed);
    },
  },
};
</script>
